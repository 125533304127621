export default [
    {
        id: 0,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [0, 0],
        },
        properties: {
            type: "start",
        },
    },
    {
        id: 1,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [0, 4960],
        },
        properties: {
            type: "control",
            code: 31,
        },
    },
    {
        id: 2,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [6028, 4960],
        },
        properties: {
            type: "control",
            code: 32,
        },
    },
    {
        id: 3,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [6028, 0],
        },
        properties: {
            type: "control",
            code: 33,
        },
    },
    {
        id: 4,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1500, 1000],
        },
        properties: {
            type: "finish",
        },
    },
    {
        id: 10,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1900, 1200],
        },
        properties: {
            type: "start",
        },
    },
    {
        id: 11,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [2700, 700],
        },
        properties: {
            type: "control",
            code: 34,
        },
    },
    {
        id: 12,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1500, 500],
        },
        properties: {
            type: "finish",
        },
    },
    {
        id: 13,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [2500, 1300],
        },
        properties: {
            type: "start",
        },
    },
    {
        id: 14,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [2500, 1800],
        },
        properties: {
            type: "control",
            code: 33,
        },
    },
    {
        id: 15,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [2000, 2100],
        },
        properties: {
            type: "finish",
        },
    },
    {
        id: 16,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1600, 2100],
        },
        properties: {
            type: "start",
        },
    },
    {
        id: 17,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1400, 1500],
        },
        properties: {
            type: "control",
            code: 44,
        },
    },
    {
        id: 18,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1000, 2100],
        },
        properties: {
            type: "finish",
        },
    },
    {
        id: 19,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1000, 2305],
        },
        properties: {
            type: "control",
            code: 42,
        },
    },
    {
        id: 20,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1500, 2600],
        },
        properties: {
            type: "control",
            code: 42,
        },
    },
    {
        id: 21,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1700, 3400],
        },
        properties: {
            type: "control",
            code: 42,
        },
    },
    {
        id: 22,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [1200, 3300],
        },
        properties: {
            type: "control",
            code: 42,
        },
    },
    {
        id: 23,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [2000, 2400],
        },
        properties: {
            type: "control",
            code: 42,
        },
    },
    {
        id: 24,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [2500, 2900],
        },
        properties: {
            type: "control",
            code: 42,
        },
    },
    {
        id: 30,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [200, 500],
        },
        properties: {
            type: "control",
            code: 35,
        },
    },
    {
        id: 31,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [200, 500],
        },
        properties: {
            type: "start",
        },
    },
    {
        id: 40,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [500, 800],
        },
        properties: {
            type: "control",
            code: 34,
        },
    },
    {
        id: 41,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [800, 4500],
        },
        properties: {
            type: "control",
            code: 34,
        },
    },
    {
        id: 42,
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [6010, 4400],
        },
        properties: {
            type: "control",
            code: 34,
        },
    },
];
