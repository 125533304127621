import { CONTROL_LABEL_DISTANCE_FACTOR } from "./isom";

export const getClosestOlFeature = (olFeatures = [], [x, y]) => {
    olFeatures.sort((a, b) => {
        const [xa, ya] = a.getGeometry().getCoordinates();
        const [xb, yb] = b.getGeometry().getCoordinates();
        const dxa = Math.abs(xa - x);
        const dya = Math.abs(ya - y);
        const dxb = Math.abs(xb - x);
        const dyb = Math.abs(yb - y);
        const distanceA = Math.sqrt(dxa * dxa + dya * dya);
        const distanceB = Math.sqrt(dxb * dxb + dyb * dyb);
        return distanceA - distanceB;
    });
    return olFeatures[0];
};

export const getAngleBetweenPoints = ([x1, y1], [x2, y2]) => {
    const dy = Math.abs(y2 - y1);
    const dx = Math.abs(x2 - x1);
    if (dy === 0) {
        if (x1 <= x2) {
            return 0;
        }
        return Math.PI;
    }
    if (dx === 0) {
        if (y1 <= y2) {
            return Math.PI / 2;
        }
        return (3 * Math.PI) / 2;
    }
    const alpha = Math.atan(dy / dx);
    if (x1 < x2 && y1 < y2) {
        return alpha;
    }
    if (x1 > x2 && y1 < y2) {
        return Math.PI - alpha;
    }
    if (x1 > x2 && y1 > y2) {
        return Math.PI + alpha;
    }
    if (x1 < x2 && y1 > y2) {
        return Math.PI * 2 - alpha;
    }
};

/**
 * Returns a point that is `distance` away from `point1`
 * in the direction of `point2`
 */
export const getPointAtDistanceBetweenPoints = (point1, point2, distance) => {
    const [x1, y1] = point1;
    const [x2, y2] = point2;
    const alpha = Math.atan(Math.abs(y2 - y1) / Math.abs(x2 - x1));
    const dx = distance * Math.cos(alpha);
    const dy = distance * Math.sin(alpha);
    let x, y;
    if (x1 < x2) {
        // Target is to the right of point1
        x = x1 + dx;
    } else {
        // Target is to the left of point1
        x = x1 - dx;
    }
    if (y1 < y2) {
        // Target is above point1
        y = y1 + dy;
    } else {
        // Target is below point1
        y = y1 - dy;
    }
    return [x, y];
};

export const RAD_TO_DEG = (rad) => rad * (180 / Math.PI);

export const getGreatestAverageAngleBetweenPoints = (point, neighborPoints = []) => {
    let targetAngle = 0;
    if (neighborPoints.length === 1) {
        targetAngle = getAngleBetweenPoints(neighborPoints[0], point);
    } else if (neighborPoints.length > 1) {
        let greatestAngleDiff = 0;
        const angles = neighborPoints.map((neighbor) => getAngleBetweenPoints(point, neighbor)).sort((a, b) => a - b);
        for (let i = 0; i < angles.length; i++) {
            const angle = angles[i];
            const nextAngle = angles[(i + 1) % angles.length];
            let angleDiff = nextAngle - angle;
            if (angleDiff < 0) {
                angleDiff = nextAngle + (Math.PI * 2 - angle);
            }
            if (angleDiff > greatestAngleDiff) {
                greatestAngleDiff = angleDiff;
                if (nextAngle - angle < 0) {
                    targetAngle = ((angle + nextAngle) / 2 + Math.PI) % (Math.PI * 2);
                } else {
                    targetAngle = (angle + nextAngle) / 2;
                }
            }
        }
    }
    return targetAngle;
};

export const getLabelCoordinates = ({ control, course, controls, scale, dpi, isom }) => {
    const neighborControls = course.controls
        .filter((_, i) => {
            if (i > 1 && course.controls[i - 1] === control.id) {
                return true;
            } else if (i < course.controls.length - 1 && course.controls[i + 1] === control.id) {
                return true;
            }
            return false;
        })
        .map((id) => controls.find((c) => c.id === id));
    const angle = getGreatestAverageAngleBetweenPoints(
        control.geometry.coordinates,
        neighborControls.map((c) => c.geometry.coordinates)
    );
    const distance = scaleMillimetersToMapUnits(
        isom.CONTROL_DIAMETER_MM * CONTROL_LABEL_DISTANCE_FACTOR,
        scale,
        dpi,
        isom
    );
    const offsets = [distance * Math.cos(angle), distance * Math.sin(angle)];
    return [control.geometry.coordinates[0] + offsets[0], control.geometry.coordinates[1] + offsets[1]];
};

export const scaleMillimetersToMapUnits = (number, scale, dpi, isom) => {
    const numberScaled = (isom.SCALE_FACTOR / scale) * number;
    const dpm = dpi / 25.4;
    return numberScaled * dpm;
};
