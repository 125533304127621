import { createSlice } from "@reduxjs/toolkit";
import testControls from "../assets/test-controls";
import testCourse from "../assets/test-course";
import { getLabelCoordinates } from "../helpers/calculations";

const initialState = {
    controls: testControls,
    courses: [testCourse],
    // controls: [],
    // courses: [],
    selectedCourseId: null,
    selectedControlId: null,
};

const generateAvailableId = (start, predicate) => {
    let id = start;
    while (predicate(id)) {
        id++;
    }
    return id;
};

const getControlDescription = (control) => {
    let desctiption = `${control.properties.type}`;
    if (desctiption === "control") {
        desctiption += ` (id ${control.id}, code ${control.properties.code})`;
    } else {
        desctiption += ` (id ${control.id})`;
    }
    return desctiption;
};

const getControlLabel = (state, { controlId, course }) => {
    const { controls } = state;
    let counter = 1;
    const numbers = [];
    for (const id of course.controls) {
        const control = controls.find((control) => control.id === id);
        if (control.properties.type === "control") {
            if (control.id === controlId) {
                numbers.push(counter);
            }
            counter++;
        }
    }
    return numbers.join("/");
};

const generateLabel = () => ({
    type: "Feature",
    geometry: {
        type: "Point",
    },
    properties: {},
});

const updateLabels = (state, { scale, dpi, isom }) => {
    const { courses, controls } = state;
    for (const course of courses) {
        for (const controlId of course.controls) {
            const control = controls.find((control) => control.id === controlId);
            course.labels[controlId] = course.labels[controlId] ?? generateLabel();
            course.labels[controlId].geometry.coordinates = getLabelCoordinates({
                control,
                course,
                controls,
                scale,
                dpi,
                isom,
            });
            course.labels[controlId].properties.label = getControlLabel(state, { controlId, course });
        }
    }
};

export const generateControl = ({ coordinates, type, controls }) => {
    const control = {
        id: generateAvailableId(1, (id) => controls.find((control) => control.id === id)),
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates,
        },
        properties: {
            type,
        },
    };
    if (control.properties.type === "control") {
        control.properties.code = generateAvailableId(31, (id) =>
            controls.find((control) => control.properties.code === id)
        );
    }
    return control;
};

export const courseManager = createSlice({
    name: "controls",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addControl: (state, action) => {
            const { controls, courses } = state;
            const { coordinates, type, courseId, afterControlId, scale, dpi, isom } = action.payload;

            let control = controls.find(
                (control) => JSON.stringify(control.geometry.coordinates) === JSON.stringify(coordinates)
            );
            if (!control) {
                control = generateControl({ coordinates, type, controls });

                // Redux Toolkit allows us to write "mutating" logic in reducers. It
                // doesn't actually mutate the state because it uses the Immer library,
                // which detects changes to a "draft state" and produces a brand new
                // immutable state based off those changes
                state.controls.push(control);

                console.log(`Created ${getControlDescription(control)}`);
            }

            state.selectedControlId = control.id;

            if (courseId) {
                const course = courses.find((course) => course.id === courseId);
                let index = course.controls.length;
                if (afterControlId !== undefined) {
                    index =
                        course.controls.length -
                        course.controls
                            .slice()
                            .reverse()
                            .findIndex((controlId) => controlId === afterControlId);
                }
                if (course.controls[index] === control.id) {
                    console.log("Can't add the same control twice in a row, ignoring.");
                    return;
                }
                course.controls = [...course.controls.slice(0, index), control.id, ...course.controls.slice(index)];
                console.log(`Added ${getControlDescription(control)} to course ${course.id} at index ${index}.`);
            }

            updateLabels(state, { scale, dpi, isom });
        },
        deleteControl: (state, action) => {
            const id = action.payload;
            const index = state.controls.findIndex((control) => control.id === id);
            if (index === -1) {
                throw new Error(`${action.type}: Could not find control with id ${id}`);
            }
            const control = state.controls.splice(index, 1)[0];
            for (const course of state.courses) {
                course.controls = course.controls.filter((controlId) => controlId !== id);
                delete course.labels[id];
            }
            console.log(`Deleted ${getControlDescription(control)}`);
        },
        moveControl: (state, action) => {
            const { id, coordinates, scale, dpi, isom } = action.payload;
            const control = state.controls.find((control) => control.id === id);
            control.geometry.coordinates = coordinates;

            updateLabels(state, { scale, dpi, isom });

            console.log(`Moved ${getControlDescription(control)}`);
        },
        addCourse: (state) => {
            const id = generateAvailableId(1, (id) => !!state.courses.find((course) => course.id === id));
            const course = {
                id,
                name: `Course ${id}`,
                controls: [],
                labels: {},
            };
            state.courses.push(course);
            state.selectedCourseId = id;
            console.log(`Added course ${course.id}`);
        },
        deleteCourse: (state, action) => {
            const { courseId } = action.payload;
            const index = state.courses.findIndex((course) => course.id !== courseId);
            if (index === -1) {
                throw new Error(`${action.type}: Could not find course ${courseId}.`);
            }
            state.courses.splice(index, 1);
            console.log(`Deleted course ${courseId}`);
        },
        removeControlFromCourse: (state, action) => {
            const { courseId, controlId } = action.payload;
            const course = state.courses.find((course) => course.id === courseId);
            const index = course.controls.findIndex((control) => control === controlId);
            if (index === -1) {
                throw new Error(`${action.type}: Could not find control ${controlId} in course ${courseId}.`);
            }
            course.controls.splice(index, 1);
            delete course.labels[controlId];
            console.log(`Removed control ${controlId} from course ${courseId}`);
        },
        selectControl: (state, action) => {
            state.selectedControlId = action.payload;
        },
        deselectControl: (state) => {
            state.selectedControlId = null;
        },
        selectCourse: (state, action) => {
            state.selectedCourseId = action.payload;
        },
        deselectCourse: (state) => {
            state.selectedCourseId = null;
        },
        moveControlLabel: (state, action) => {
            const { id, coordinates } = action.payload;
            const course = state.courses.find((course) => course.id === state.selectedCourseId);
            const label = course.labels[id];
            label.properties.customGeometry = {
                type: "Point",
                coordinates,
            };
            console.log(`Moved label ${id} (${label.properties.label}).`);
        },
    },
});

export const {
    addControl,
    deleteControl,
    moveControl,
    addCourse,
    deleteCourse,
    removeControlFromCourse,
    selectControl,
    deselectControl,
    selectCourse,
    deselectCourse,
    moveControlLabel,
} = courseManager.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.controls)`
export const selectControls = (state) => state.courseManager.controls;
export const selectCourses = (state) => state.courseManager.courses;
export const selectSelectedCourseId = (state) => state.courseManager.selectedCourseId;
export const selectSelectedControlId = (state) => state.courseManager.selectedControlId;

export default courseManager.reducer;
