import { Component } from "preact";

import { Provider } from "react-redux";
import { store } from "../store/store";

// Code-splitting is automated for routes
import Home from "../routes/home";
import CourseContextProvider from "./CourseContextProvider";

export default class App extends Component {
    /** Gets fired when the route changes.
     *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
     *	@param {string} event.url	The newly routed URL
     */
    handleRoute = (e) => {
        this.currentUrl = e.url;
    };

    render() {
        return (
            <div id="app">
                <Provider store={store}>
                    <CourseContextProvider>
                        <Home path="/" />
                    </CourseContextProvider>
                </Provider>
            </div>
        );
    }
}
