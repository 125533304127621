import { createContext } from "preact";
import { memo } from "preact/compat";
import { useState, useMemo } from "preact/hooks";
import { isom as isomObjects } from "../helpers/isom";

export const CourseContext = createContext({
    dpi: 0,
    scale: 0,
    isom: null,
    setDpi: () => {},
    setScale: () => {},
    setIsom: () => {},
});

const CourseContextProvider = ({ children }) => {
    const [dpi, setDpi] = useState(600);
    const [scale, setScale] = useState(10000);
    const [isom, setIsom] = useState(isomObjects.isom2017);
    const contextValue = useMemo(() => ({ dpi, scale, isom, setDpi, setScale, setIsom }), [dpi, scale, isom]);

    return <CourseContext.Provider value={contextValue}>{children}</CourseContext.Provider>;
};

export default memo(CourseContextProvider);
