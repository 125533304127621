// Colors
// Pantone purple: #bb29bb - https://www.pantone.com/color-finder/Purple-C
export const COLOR_PURPLE = "#AC00AC"; // estimated to match #bb29bb 0.9 opacity
export const COURSE_SYMBOLS_OPACITY = 0.9; // estimated
export const CONTROL_LABEL_DISTANCE_FACTOR = 1.3; // based on control diameter

// Dimensions
export const isom = {
    // millimeters on a 1:15000 scale map
    isom2017: {
        START_EDGE_LENGTH_MM: 6.0,
        CONTROL_DIAMETER_MM: 5.0,
        CONTROL_NUMBER_HEIGHT_MM: 4.0,
        FINISH_INNER_DIAMETER_MM: 4.0,
        FINISH_OUTER_DIAMETER_MM: 6.0,
        LINE_WIDTH_MM: 0.35,
        SCALE_FACTOR: 15000,
    },
    // millimeters on a 1:4000 scale map
    issprom2019: {
        START_EDGE_LENGTH_MM: 7.0,
        CONTROL_DIAMETER_MM: 6.0,
        CONTROL_NUMBER_HEIGHT_MM: 4.0,
        FINISH_INNER_DIAMETER_MM: 5.0,
        FINISH_OUTER_DIAMETER_MM: 7.0,
        LINE_WIDTH_MM: 0.35,
        SCALE_FACTOR: 4000,
    },
};
